

















import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';

import { Component, Vue, Watch } from 'vue-property-decorator';

import Header from '@/components/navigation/header/Header.vue';
import LeftMenu from '@/components/navigation/left-menu/LeftMenu.vue';

import { $settingsService } from '@/services/settings.service';
import { webWorkers } from '@/services/web-workers.service';
import { $environment } from '../services/environment.service';

@Component({
    components: {
        Header,
        LeftMenu
    }
})
export default class Layout extends Vue {
    get ShowLoader(): boolean {
        const show: boolean = this.$loader.routeLoader();

        return show;
    }

    static async asyncData({ route, store }: any) {
        await $environment.whenReady();
        
        await $settingsService.getRequiredSettings();
    }

    beforeMount() {
        this.initWorkers();
    }

    mounted() {
        this.handleUnexpectedErrors();
    }

    private initWorkers() {
        try {
            const workers = (this as any).$worker.create(webWorkers.actions);

            webWorkers.init(workers);
        } catch (error) {
        }
    }
        
    private handleUnexpectedErrors() {
        window.onerror = (message, script, line, num, error) => {
            this.$modal.showError(new Error(message as string));
        };
    }
}

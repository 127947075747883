






































import { Component, Vue } from 'vue-property-decorator';
import ApportKpi from '@/components/kpi/ApportKpi.vue';
import { $kpi } from '@/services/kpi.service';
import { $environment } from '@/services/environment.service';
import { IKpi, IKpiData } from '@/interfaces/web-api/kpi.interfaces';
import { $settingsService } from '@/services/settings.service';
import { mapToUnique } from '@/utilities/map-to-unique.function';
import WidgetWrapper from "@/components/kpi/WidgetWrapper.vue";
import { dataService } from '@/services/data.service';
import { $user } from '@/services/user.service';
import { ButtonMsg } from '@/utilities/translation.enum';
import { time } from 'console';

@Component({
    components: {
        ApportKpi,
        WidgetWrapper
    }
})
export default class HomePage extends Vue {

    public timeDiff:number = 0;
    public widgetEnabled:boolean = false;
    protected static async asyncData({ route, store }: any) {
        await $environment.whenReady();

        await $settingsService.whenReady();

        await $kpi.fetchData();
    }
    
    private get kpis() {
        const kpis = $kpi.getData();

        return kpis as IKpi[];
    }
    
    get UpdateText():string {
        return this.$T('Widget.updated').value;
    }

    get DashboardText():string {
        return this.$T('Widget.Dashboard').value;
    }

    get ShowActionLoader(): boolean {
      const show: boolean = this.$loader.actionLoader();
      return show;
    }

    get CustomizeText(): string {
        return this.$T(ButtonMsg.CUSTOMIZE).value; 
    }

    get IsAdmin(): boolean {
      return $user.isAdmin;
    }
    
    get TimeAgoText():string {
      return !this.timeDiff ? this.$T('Widget.updatedJustNow').value : (this.timeDiff  <= 1 ? this.$T('Widget.updatedSingular').value : this.$T('Widget.updatedPlural').value);
    }

    get Rows(): any {
        const kpis = this.kpis.filter(kpi => kpi.enabled);

        const groups = mapToUnique<number>(kpis, 'row').sort((a, b) => a - b); 

        return groups.map(group => kpis.filter(kpi => kpi.row === group)
                                            .sort((a, b) => a.order - b.order));
    }
    
    mounted() {
        if (this.kpis && this.kpis.length) {
            this.prepareSignalR();
        }
    }

    navigateToDashboardSettings():void {
        this.$router.push({name:'dashboard-settings', params:{source: 'dashboard'}});
    }

    updateWidgetVal(val:boolean):void {
        this.widgetEnabled = val;
    }

    updateTimeDiff(val:number):void {
        this.timeDiff = val;
    }

    private prepareSignalR() {
        // note: disabled since BE not ready (no View Level available)
        return;
        // todo: verify viewTypes
        const viewTypes = [
            'OutBoundOrderReportData'
            ];
        
        dataService.initSignalR('ReportDataView', viewTypes , { callback: this.handleSignalR.bind(this) });
    }
    
    private handleSignalR(data: IKpiData[]) {
        try {
            $kpi.update(data);
        } catch (error) {
        }
    }

    deactivated() {
        this.$loader.routeEnd();
    }


}

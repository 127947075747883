





































import LeftMenuIcon from '../../icons/LeftMenuIcon.vue';
import { MenuState, DropDownState } from '@/interfaces/common.interface';
import { IRouteConfigExtended } from '@/interfaces/settings.interface';
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface ILeftMenuItemConfig {
    readonly id: number;
    readonly name: string;
    readonly navigateTo: string;
    mode: MenuState;
    readonly routeName: string;

    readonly nested?: ILeftMenuItemConfig[];
}
export class LeftMenuItemConfig implements ILeftMenuItemConfig {
    readonly id: number;
    readonly name: string;
    readonly navigateTo: string;
    readonly routeName: string;
    readonly nested?: ILeftMenuItemConfig[];

    constructor(id: number, config: IRouteConfigExtended, public mode: MenuState) {
        this.id = id;
        const { displayName, routeName, routes } = config;
        this.name = displayName;
        this.routeName = routeName;
        this.navigateTo = routeName === 'dashboard' ? '/' : `/${routeName}`;
        if (routes) {
            this.nested = routes.filter(route => route.isAllowed)
                .sort((a, b) => a.order - b.order)
                .map((route, idx) => new LeftMenuItemConfig(idx, route, mode));
        }
    }
}

@Component({
    components: {
        LeftMenuIcon
    }
})
export default class LeftMenuItem extends Vue {
    [x: string]: any;
    @Prop({ type: Object }) config!: ILeftMenuItemConfig;

    accordionState: DropDownState = 'dropDownCollapsed';

    onToggle() {
        if (!this.isAccordion) { return; }

        this.accordionState = this.accordionState === 'dropDownCollapsed' ? 'dropDownExtended' : 'dropDownCollapsed';
    }
    onHover(a:any,event:any) {
       this.$emit('hover',a,event)
    }

    onBlur(e:any) {
        this.$emit('blur',e)
    }
    get isActive(): boolean {
        const configs = this.isAccordion ? this.config.nested || [] : [this.config];

        return this.$route && configs.some(config => config.routeName === this.$route.name);
    }

    get isAccordion(): boolean {
        return !!this.config.nested && !!this.config.nested.length;
    }

    get ClassName(): string {
        return `${this.isActive ? 'active' : ''} ${this.isAccordion ? 'accordion-menu ' + this.accordionState : ''}`.trim();
    }

    get Title(): string {
        const { name } = this.config;
        if (!name) { return ''; }
        return this.$T(`Menu.${name}`).value;
    }

    NestedTitle(routeName: string): string {
        if (!routeName) { return ''; }

        return this.$T(`Menu.${routeName}`).value;
    }

    get ImgSrc(): string {
        return '@/assets/new-icons/Dashboard.svg';
    }

    ActiveClass(route: ILeftMenuItemConfig): string {
        if (this.$route && route.routeName === this.$route.name) {
            return 'active';
        }
        return '';
    }

    mounted() {
        this.$eventBus.$on('setting', () => {
            this.config.mode = 'collapsed';
        });
    }
}

import { render, staticRenderFns } from "./LeftMenuIcon.vue?vue&type=template&id=58791a32&scoped=true&"
import script from "./LeftMenuIcon.vue?vue&type=script&lang=ts&"
export * from "./LeftMenuIcon.vue?vue&type=script&lang=ts&"
import style0 from "./LeftMenuIcon.vue?vue&type=style&index=0&id=58791a32&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58791a32",
  null
  
)

component.options.__file = "LeftMenuIcon.vue"
export default component.exports
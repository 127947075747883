




















import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';

@Component({
  components: {
    CustomCheckbox
  }
})
export default class  SingleValueWidget extends Vue {
  @Prop() widgetData!: IWidgetItem;
  @Prop() isSettingsPage!: boolean;

  onSelect(widgetData:any) {
      this.$emit('onSelect',widgetData);
  }

  naviagteTo() {
    this.$emit('naviagateTo', this.widgetData);
  }
};

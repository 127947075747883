import { $environment } from '@/services/environment.service';
import { $settingsService } from '@/services/settings.service';
import { T9nService } from '@/services/t9n.service';
import { $user } from '@/services/user.service';
import { SettingsMsg } from '@/utilities/translation.enum';

export default class HeaderT9n extends T9nService {
    get BU(): string {
        return this.getBU($settingsService.businessUnitName);  
    }
    get BusinessUnit(): string {
        return $settingsService.businessUnitName;  
    }
    get SettingsTitle(): string {
        return this.T(SettingsMsg.SETTINGS);
    }
    get SupportTitle(): string {
        return this.T(SettingsMsg.SUPPORT);
    }
    get UserName(): string {
        return $user.displayName || 'Undefined';
    }
    get Version(): string {
        return `${this.T(SettingsMsg.VERSION)}: ${this.version}`;  
    }
    get Language(): string {
        return `${this.T(SettingsMsg.LANGUAGE)}: ${this.language}`;  
    }
    get UserManagementTitle(): string {
        return this.T(SettingsMsg.USERS);
    }
    get ImportDataTitle(): string {
        return this.T(SettingsMsg.IMPORT_DATA);
    }
    get IntegrationMonitorTitle(): string {
        return this.T(SettingsMsg.INTEGRATION_MONITOR);
    }
    get KpiManagementTitle(): string {
        return this.T(SettingsMsg.KPI_MANAGEMENT);
    }
    get CauseCodesTitle(): string {
        return this.T(SettingsMsg.CAUSE_CODES);
    }
    get PrinterManagementTitle(): string {
        return this.T(SettingsMsg.PRINTER_MANAGEMENT);
    }
    get DownloadsTitle(): string {
        return this.T(SettingsMsg.DOWNLOADS);
    }
    get UserSettingsTitle(): string {
        return this.T(SettingsMsg.USER_SETTINGS);
    }
    get StockAttributesTitle(): string {
        return this.T(SettingsMsg.STOCKS_ATTRIBUTES);
    }
    get ActiveUsersTitle(): string {
        return this.T(SettingsMsg.ACTIVE_USERS);
    }
    get IntegrationImportsTitle(): string {
        return this.T(SettingsMsg.INTEGRATION_IMPORTS);
    }
    get IntegrationRequestsTitle(): string {
        return this.T(SettingsMsg.INTEGRATION_REQUESTS);
    }
    get IntegrationMessagesTitle(): string {
        return this.T(SettingsMsg.INTEGRATION_MESSAGES);
    }
    get LogoutBtnText(): string {
        return this.T(SettingsMsg.LOGOUT);
    }

    private getBU(businessUnitName: string) {
        if (!businessUnitName) { return ''; } 
        if (businessUnitName.length === 1) { return businessUnitName.toUpperCase(); }
        // todo: improve to take 2 initials (not only 2 first)
        return businessUnitName.substring(0, 2).toUpperCase(); 
    }
    
    private get language(): string {
        return $settingsService.currentLanguage || $settingsService.defaultLanguage;
    }
    
    private get version(): string {
        return $user.isAdmin ? $environment.versionExtended : $environment.version;
    }
}







































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import LeftMenuItem, { LeftMenuItemConfig } from './LeftMenuItem.vue';
import { $settingsService } from '@/services/settings.service';
import { MenuState } from '@/interfaces/common.interface';
import { $permission } from '@/services/permission.service';
import HeaderT9n from '../header/header.t9n';
import LeftMenuIcon from '../../icons/LeftMenuIcon.vue';
import { RouterLink } from 'vue-router';
import { settingsModule } from '@/store/modules/settings.module';


interface IState {
    userRoutes: LeftMenuItemConfig[];
    mode: MenuState;
    isSetting: boolean;
}

@Component({
    components: {
        LeftMenuItem,
        LeftMenuIcon
    }
})
export default class LeftMenu extends Vue {
    @Watch("browserWidth", {immediate:true}) onWidthChange(newVal: number) {
        this.State.mode = newVal < this.breakpointWidth ? "collapsed" : "extended";
        this.$eventBus.$emit('innerWidthChanged',newVal);
        this.State.userRoutes.forEach(config => config.mode = this.State.mode);
    }

    [x: string]: any;
    T9n = new HeaderT9n();
    browserWidth:number = window.innerWidth;
    breakpointWidth:number = 1151;
    State: IState = {
        userRoutes: [],
        mode: 'extended',
        isSetting : false
    };
        hoveredMenuItem:any =  null
      hoveredPosition = { x: 0, y: 0 }
      hoverItemName = '';

    async mounted(): Promise<void> {

        const configs = await $settingsService.getRouteConfigs();
        settingsModule.setMenuRoutes(configs);
        this.State.userRoutes = configs.filter(route => route.isAllowed)
            .sort((a, b) => a.order - b.order)
            .map((route, index) => new LeftMenuItemConfig(index, route, this.State.mode));

        this.$parent.$on('leftMenuEvent', () => {
            this.onToggle();
            this.State.isSetting = false;
            this.addSideBarClass();
        });
        window.addEventListener('resize', () => {
            this.browserWidth = window.innerWidth
        })
    }
    NestedTitle(routeName: string): string {
        if (!routeName) { return ''; }

        return this.$T(`Menu.${routeName}`).value;
    }
    onToggle(): void {
        this.State.mode = this.State.mode === 'collapsed' ? 'extended' : 'collapsed';

        this.State.userRoutes.forEach(config => config.mode = this.State.mode);
    }
    hover(menuItem:any, e:any) :void {
        this.hoverItemName = menuItem.name;
        this.hoveredMenuItem = menuItem;
        const targetElement = e.target;
        const targetRect = targetElement.getBoundingClientRect();
        const x = targetRect.left + 44; // Adjust as needed
        const y = targetRect.top;
        this.hoveredPosition.x = x;
        this.hoveredPosition.y = y;
    }

    blur(e:any):void {
        if (!(e?.relatedTarget?.classList.contains('sidebar-nav') || e?.relatedTarget?.classList.contains('nav-item'))) {
            this.hoveredMenuItem = null;
            this.hoverItemName = '';
        }
    }
    get DashboardConfig(): LeftMenuItemConfig {
        return new LeftMenuItemConfig(-1, {
            displayName: 'Dashboard',
            routeName: 'dashboard',
            isAllowed: true,
            order: -1
        }, this.State.mode);
    }

    get noSubMenu():boolean{
        return !this.hoveredMenuItem?.nested?.length
    }

    openSetting() {
        this.State.isSetting = !this.State.isSetting;
        this.addSideBarClass();
        if(this.State.isSetting) {
            this.State.mode = 'collapsed';
        }
        this.$eventBus.$emit('setting');
    }

    addSideBarClass() {
        const content = document.getElementsByClassName("main-content");
        if(this.State.isSetting) {
            if(content && content.length > 0) {
                content[0].classList.add("settings-sidebar-open");
            }
        }
        else{
            content[0].classList.remove("settings-sidebar-open");
        }
    }

    HasPermissionFor(key: string) {
        return $permission.hasFor('header', 'settings', key);
    }

    ActiveClass(route: string): string {
        if (this.$route && route === this.$route.name) {
            return 'active';
        }
        return '';
    }

    get Settings(): string {
        return this.$T('Settings.Settings').value;
    }
    get General(): string {
        return this.$T('Settings.General').value;
    }
    get Warehouse(): string {
        return this.$T('Settings.Warehouse').value;
    }
    get Integration(): string {
        return this.$T('Settings.Integration').value;
    }
}


import { ICustomConfirmModal, ICustomDynamicConfigExtend, ICustomErrorModal, ICustomModalConfig, ICustomSelectModal, ICustomUserChoiceModal } from '@/interfaces/custom-modal.interface';
import { IUserChoice, IUserChoiceOptionItem, UserChoiceType } from '@/interfaces/web-api/user-choice.interface';
import { modalModule } from '@/store/modules/modal.module';
import { ApportError } from '@/utilities/error.extensions';
import { loaderService } from './custom-loader.service';

export interface ICustomModalService {
    show: (dynamic: ICustomModalConfig) => number;

    showConfirm: (confirm: ICustomConfirmModal) => number;

    showError: (error: ApportError | Error | string | unknown, isHtml?: boolean, showStack?: boolean) => void;

    showSelect: (select: ICustomSelectModal, initZIndex?: number) => number;

    showSuccess: () => number;

    showUserChoice: (userChoice: IUserChoice) => number;

    showWarning: (message: string) => number;

    close: (id: number) => void;

    count: () => number;

    nextIndex: () => number;

    showWarningCallback: (title: string, message: string,table: string, callback: () => void) => void
}
interface IModalError {
    er: ApportError;
    id: number;
}

class CustomModalService implements ICustomModalService {
    private activeErrors: IModalError[] = [];

    show(dynamic: ICustomModalConfig): number {
        if (!dynamic || !dynamic.template) {
            this.showError(new ApportError('Template name is required for dynamic modal!', dynamic));

            return -1;
        }

        const id = this.count();

        dynamic.id = id;

        modalModule.addModal(dynamic);

        return id;
    }

    showConfirm(confirm: ICustomConfirmModal): number {
        confirm.id = this.count();
        confirm.template = 'ConfirmModal';

        modalModule.addModal(confirm);

        return confirm.id;
    }

    showError(error: ApportError | Error | string | any, isHtml: boolean = false, showStack: boolean = true): void {
        if (!error) { return; }

        const apportError = this.parseError(error);
        const id = this.count();

        if (this.hasError(apportError, id)) { return; }

        const { context, message, stack, name, title } = apportError;
        const modal: ICustomErrorModal = {
            id,
            template: 'ErrorModal',
            dynamic: {
                name,
                message,
                stack: context || stack,
                isHtml,
                showStack,
                title
            }
        };

        modalModule.addModal(modal);
    }

    showSelect(select: ICustomSelectModal): number {
        select.id = this.count();
        select.template = 'SelectModal';

        modalModule.addModal(select);

        return select.id;
    }

    showSuccess(): number {
        const id = this.count();
        const success: ICustomModalConfig = {
            id,
            template: 'SuccessModal',
            dynamic: {}
        };

        modalModule.addModal(success);

        return id;
    }

    showUserChoice(userChoice: IUserChoice, callback?: (option: IUserChoiceOptionItem) => void): number {
        loaderService.closeAll();

        const id = this.count();
        const { type } = userChoice;
        const template = type === UserChoiceType.MaterialItemType ? 'UserChoiceListModal' : 'UserChoiceModal';

        const modal: ICustomUserChoiceModal = {
            id,
            template,
            dynamic: userChoice,
            onClose: callback
        };

        modalModule.addModal(modal);

        return id;
    }

    showWarning(message: string): number {
        const id = this.count();
        const warning: ICustomModalConfig = {
            id,
            template: 'WarningModal',
            dynamic: {
                message
            }
        };

        modalModule.addModal(warning);

        return id;
    }

    showWarningCallback(title: string, message: any, table: string, callback: () => void): number {
        const id = this.count();
        const warning: ICustomDynamicConfigExtend = {
            id,
            template: 'WarningModal',
            title: title,
            dynamic: {
                message,
                table,
                resolve: callback
            },
        };

        modalModule.addModal(warning);

        return id;
    }

    showConFirmCallback(title: string, message: any, table: string, callback: () => void, cancelBtn?:any,acceptBtn?:any): number {
        const id = this.count();
        const warning: ICustomDynamicConfigExtend = {
            id,
            template: 'ConfirmModal',
            title: title,
            dynamic: {
                acceptBtn,
                cancelBtn,
                message,
                table,
                resolve: callback
            },
        };

        modalModule.addModal(warning);

        return id;
    }

    showSignalRModal(message: string): number {
        const id = this.count();
        const signalRModal: ICustomErrorModal = {
            id,
            template: 'SignalRModal',
            dynamic: {
                name: 'SignalR',
                message
            }
        };

        modalModule.addModal(signalRModal);

        return id;
    }

    close(id: number): void {
        if (loaderService.routeLoader()) {
            // in case of route error
            loaderService.routeEnd();
        }

        modalModule.closeModal(id);

        this.removeError(id);
    }

    count(): number {
        return modalModule.getCount;
    }

    nextIndex(): number {
        modalModule.incrementZIndex();

        return modalModule.zIndex;
    }

    private parseError(error: ApportError | Error | string): ApportError {
        if (error instanceof Error) {
            return error as ApportError;
        } else if (typeof error === 'string') {
            return new ApportError(error);
        } else {
            return new ApportError('Unexpected error occured', error);
        }
    }

    private hasError(error: ApportError, id: number) {
        if (this.activeErrors.findIndex(e => e.er.message === error.message) === -1) {
            this.activeErrors.push({
                er: error,
                id
            });

            return false;
        }

        return true;
    }

    private removeError(id: number) {
        const idx = this.activeErrors.findIndex(error => error.id === id);

        if (idx === -1) { return; }

        this.activeErrors.splice(idx, 1);
    }

    
}

export const $modalService = new CustomModalService();

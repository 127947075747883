







import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import DOMHelper from '@/utilities/dom.helper';

export type ICmCheckboxPlacement = 'left' | 'right';

@Component({})
export default class CustomCheckbox extends Vue {
    @Prop({ type: String }) label!: string;
    @Prop({ type: Boolean }) checked!: boolean;
    @Prop({ type: Boolean }) readonly!: boolean;
    @Prop({ type: String }) placement!: ICmCheckboxPlacement;

    @Watch('checked') onCheckedChanged(checked: boolean) {
        this.State.checked = checked;
    }
    
    State = {
        checked: this.checked,
        className: '',
        isTextOverflow: false
    };
    
    get ClassName() {
        this.State.checked = this.checked;
        this.State.className = '';
        
        if (this.State.checked) {
            this.State.className += 'checked';
        }

        if (this.readonly) {
            this.State.className = `${this.State.className} readonly`.trim();
        }
        
        return `${this.State.className} ${this.placement || 'left'} ${this.State.isTextOverflow ? 'overflow' : ''}`.trim();
    }

    mounted() {
        if (this.label) {
            const { input } = this.$refs;

            if (input && input instanceof Element && input.clientWidth) {
                const textWidth = DOMHelper.calcTextWidth(this.label, input);

                if (textWidth > input.clientWidth + 1) {
                    this.State.isTextOverflow = true;
                }
            }
        }
    }
    
    toggle() {
        if (this.readonly) {
            return;
        }
        this.onChange(!this.State.checked);
    }
    
    private onChange(checked: boolean) {
        if (checked !== this.State.checked) {
            this.State.checked = checked;
            this.$emit('change', checked);
        }
    }
}

import { render, staticRenderFns } from "./SingleValueWidget.vue?vue&type=template&id=1c601453&scoped=true&"
import script from "./SingleValueWidget.vue?vue&type=script&lang=ts&"
export * from "./SingleValueWidget.vue?vue&type=script&lang=ts&"
import style0 from "./SingleValueWidget.vue?vue&type=style&index=0&id=1c601453&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c601453",
  null
  
)

component.options.__file = "SingleValueWidget.vue"
export default component.exports


















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SingleValueWidget from './templates/SingleValueWidget.vue';
import { $environment } from '@/services/environment.service';
import WIdgetService from './widget.service';
import { $user } from '@/services/user.service';
import { ButtonMsg } from '@/utilities/translation.enum';


@Component({
  components: {
    SingleValueWidget
  }
})

export default class WidgetWrapper extends Vue {
  @Prop({ type: Function, default: ()=>{} }) navigateToDashboardSettings: any;

  @Watch('State.timeDifference') onTimeDifferenceChanged(newtime:number, oldTime:number ) {
        if(newtime >= this.NextCallTime) {
          this.getWidgets('fromWatcher');
        }
        if (newtime !== oldTime) {
          this.$emit('timeDiff', newtime);
        }
      }
  @Watch('notWidgetEnabled') onNotWidgetEnabledChanged(newVal:boolean, oldVal:boolean ) {
    if (newVal !== oldVal) {
      this.$emit('widgetEnabled', newVal);
    }
  }

  protected service = new WIdgetService();
  State: IState = {
    widgets: [],
    finalWidgets: [],
    selected: [],
    responseTime:0,
    timeDifference:0,
    isInitialMount: true,
    intervalId:null
  }

    get IsAdmin(): boolean {
      return $user.isAdmin
    }

    get NextCallTime(): number {
      return Number($environment.VUE_APP_WIDGET_UPDATE_TIME);
    }

    get SaveBtnText(): string {
        return this.$T(ButtonMsg.SAVE).value; 
    }

    get NothingLabelText(): string {
        return this.$T(`Widget.NothingLabelText`).value; 
    }

    get GetStartedText(): string {
        return this.$T('Widget.GetStartedText').value; 
    }

    get VisitDashboard(): string {
        return this.$T(ButtonMsg.VISITDASHBOARD).value; 
    }

    get ToSettings(): string {
        return this.$T(ButtonMsg.TOSETTINGS).value; 
    }

    get SelectionMsg():string {
      return this.$T('Widget.SelectionMsg').value;
    }

    get IsSettingsPage():boolean {
      return this.$route.name === 'dashboard-settings';
    }

    get notWidgetEnabled():boolean {
      return  this.IsSettingsPage ? true : this.State.finalWidgets.some((group:any) => group.values.some((item:IWidgetItem) => item.isEnabled));
    }

    get fromDashboard():boolean {
      return this.$route.params?.source === 'dashboard';
    }

  public filterEnabledItems(items: Array<IWidgetItem>): Array<IWidgetItem> {
   if (!this.IsSettingsPage) {
    return items.filter((item: any) => item.isEnabled);
    }
    return items;
  }

  goBackDashboard():void {
    this.$router.push({name:'dashboard'});
  }

  navigateTo(item:any): false|void {
    if (!item.pageName || !item.filters?.length) {
      return false;
    }
    this.$loader.routeStart();
    this.$router.push({name: item.pageName, params:{filters:item.filters,  forceCall: 'true'}})
  }

  async mounted() {
    if (this.State.isInitialMount) {
      this.getWidgets();
      this.State.isInitialMount = false;
    }
  }

  activated() {
    if (!this.State.isInitialMount) {
      this.getWidgets();
    }
  }

 async getWidgets(source?:string) {
  this.State.widgets = await this.service.getWidgetsData(source);
  this.State.responseTime= Date.now(); // Save the response time
        this.updateTimeDifference(); // Initial calculation
        this.State.intervalId = setInterval(() => {
           this.updateTimeDifference();
        }, 1000);
        if (this.State.widgets) {
          this.State.finalWidgets = this.service.syncData(this.State.widgets);
        }
      }

  async save() {
    this.getSelectedFrmServer();
    this.service.saveWidget(this.State.selected);
  }

  public onSelect(widgetData:IWidgetItem):void {
    if (!this.IsSettingsPage) {
      this.navigateTo(widgetData)
      return;
    }
    this.getSelectedFrmServer();
    widgetData.isEnabled = !widgetData.isEnabled;
      if (widgetData.isEnabled) {
          this.State.selected.push(widgetData.type);
      } else {
        const index = this.State.selected.indexOf(widgetData.type);
        if (index !== -1) {
          this.State.selected.splice(index, 1);
        }
      }
    }

    public getSelectedFrmServer():void {
      const enabledTypes = this.State.finalWidgets.reduce((types: any[], group: { values: any[]; }) => {
      group.values.forEach((item:IWidgets) => {
        if (item.isEnabled) {
          types.push(item.type);
        }
      });
      return types;
    }, []);
    this.State.selected = enabledTypes;
    }

    updateTimeDifference():void {
        if (this.State.responseTime) {
          let currentDate:any = new Date();
          const responseTime:any = new Date(this.State.responseTime);
          const timeDifferenceInMilliseconds = currentDate - responseTime;
          if( !((Math.floor(timeDifferenceInMilliseconds / (60 * 1000))) > this.NextCallTime)) {
            this.State.timeDifference = Math.floor(timeDifferenceInMilliseconds / (60 * 1000));
          }
        }
      }

    beforeDestroy() {
      clearInterval(this.State.intervalId);
   }

    deactivated() {
        clearInterval(this.State.intervalId);
    }
}

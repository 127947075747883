export default class ObjectHelper {
    static isTheSame(a: any, b: any) {
        if ((a && !b) || (!a && b)) { return false; }
        if (!a && !b) { return true; } 

        // note: as functions are 
        const aKeys = Object.keys(a).join(',');
        const bKeys = Object.keys(b).join(',');

        return JSON.stringify(a) === JSON.stringify(b) && aKeys === bKeys;
    }

    static create(path: string, value: any = null): any {
        const keys = !!path ? path.split('.') : [];
        const lastName = keys.pop();

        if (!lastName) { return {}; }

        let obj = { [lastName]: value };
        
        for (const key of keys.reverse()) {
            const parentObj = { [key]: obj };
            obj = parentObj;
        }

        return obj;
    }

    static deepClone<T>(obj: any): T {
        return !!structuredClone ? structuredClone(obj) : JSON.parse(JSON.stringify(obj));
    }

    /**
     * 
     * @param base object with default values
     * @param toAssign objects which should be used to update the base object
     * @returns updated base object
     */
    static deepAssign(base: any, ...toAssign: any[]): any {
        const assign = (b: any = {}, o: any = {}) => {
            for (const key in o) {
                if (Object.prototype.hasOwnProperty.call(o, key)) {
                    if (Array.isArray(o[key])) {
                        b[key] = this.deepClone(o[key]);
                    } else if (typeof(o[key]) === 'object') {
                        b[key] = assign(b[key], o[key]);
                    } else {
                        b[key] = o[key];
                    }
                }
            }
            return b;
        };

        let updated = this.deepClone(base);
        for (const obj of toAssign) {
            updated = assign(updated, obj);
        }

        return updated;
    }

    /**
     * 
     * @param base object with default values
     * @param toMerge objects which should be merged with the base object
     * @returns updated base object
     */
    static deepMerge(base: any, ...toMerge: any[]): any {
        const merge = (b: any = {}, o: any = {}) => {
            for (const key in o) {
                if (Object.prototype.hasOwnProperty.call(o, key)) {

                    if (Array.isArray(o[key])) {
                        const baseArray: any[] = b[key] || [];
                        baseArray.push(...o[key]);

                        b[key] = [...new Set(baseArray)];
                    } else if (typeof(o[key]) === 'object') {
                        b[key] = merge(b[key], o[key]);
                    } else {
                        b[key] = o[key];
                    }
                }
            }
            return b;
        };

        let updated = this.deepClone(base);
        for (const obj of toMerge) {
            updated = merge(updated, obj);
        }

        return updated;
    }

    static update(obj: any, path: string, value: any): void {
        const parts = path.split('.');
        if (!path.length) {
            throw new Error('Object update error: Path not provided');
        }

        while (parts.length > 1) {
            const node = parts.shift();
            if (node && Object.prototype.hasOwnProperty.call(obj, node)) {
                obj = obj[node];
            } else {
                throw new Error('Object update error: Invalid node');
            }
        }
        
        obj[parts[0]] = value;
    }
}

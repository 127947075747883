











































import { Component, Vue } from 'vue-property-decorator';
import CustomDialogMenu from '../../ui/CustomDialogMenu.vue';
import HeaderT9n from './header.t9n';
import { $user } from '@/services/user.service';
import { $permission } from '@/services/permission.service';
import { MenuState } from '@/interfaces/common.interface';

interface IState {
    mode: MenuState;
}


@Component({
    components: { CustomDialogMenu }
})
export default class Header extends Vue {
    [x: string]: any;
    breakpointWidth:number = 1151;
    disabledBtn:boolean = false;
    State: IState = {
        mode: 'extended'
    };


    T9n = new HeaderT9n();

    goToHomeScreen() {
        this.$router.push({ name: 'dashboard' });
    }

    beforeMount() {
        this.$eventBus.$on("innerWidthChanged", (data: number) => {
            this.State.mode = data < this.breakpointWidth ? "collapsed" : "extended";
            this.disabledBtn = data < this.breakpointWidth ? true : false;
        });
    }
    async logout(): Promise<void> {
        await $user.logout();

        if (this.$route.path !== 'login') {
            this.$router.push({ name: 'login' });
        }
    }

    onToggle(): void {
        this.State.mode = this.State.mode === 'collapsed' ? 'extended' : 'collapsed';

        this.$parent.$emit('leftMenuEvent');
    }

}

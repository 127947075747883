import moment from 'moment';
import { formatDates } from './format-dates.function';
import StringHelper from './string.helper';

export default class DateHelper {
    static readonly DateFormat = 'DD/MM/YYYY';
    static readonly DateTimeFormat = 'DD/MM/YYYY hh:mm:ss A';
    static readonly BaseDateTimeFormat = 'YYYY-MM-DDThh:mm';

    static formatDate(option: string, format: string): string {
        if (option.includes('0001')) {
            return '';
        }
        const date = new Date(option);
        return DateHelper.format(date, format);
    }
    static toDate(date: string | Date | moment.Moment): Date {
        if (date instanceof Date) {
            return date;
        }

        if (moment.isMoment(date)) {
            return date.toDate();
        }

        if (this.isDateString(date)) {
            let hh = '00';
            let mm = '00';

            const timeSeparator = date.includes('T') ? 'T' :
                                date.includes(' ') ? ' ' : null;
            if (timeSeparator) {
                const [dateStr, time] = date.split(timeSeparator);
                const timeStr = StringHelper.replaceAll(time, '%3A', ':');

                [hh, mm] = timeStr.split(':');
                date = dateStr;
            }
            
            let [yyyy, MM, dd] = date.split('-');

            if (dd.length > yyyy.length) {
                const year = dd;
                dd = yyyy;
                yyyy = year;
            }

            return new Date(
                parseInt(yyyy, 10),
                parseInt(MM, 10) - 1,
                parseInt(dd, 10),
                parseInt(hh, 10),
                parseInt(mm, 10)
            );
        }

        throw new Error('DateHelper: Bad input');
    }

    static format(date: string | Date | moment.Moment | null, format: string = DateHelper.DateFormat): string {
        if (!date) {
            return '';
        }
        if (!moment.isMoment(date)) {
            date = moment(date);
        }

        return date.format(format);
    }

    static formatMany(format: string, ...data: any[]): any[] {
        return formatDates(format, data);
    }

    static isDateString(dateString: any): dateString is string {
        if (!dateString || typeof(dateString) !== 'string') {
            return false;
        }

        const validDateSeparators = ['-', '/', '.'];
        const validFormats = [
            DateHelper.DateFormat,
            DateHelper.DateTimeFormat,
            moment.ISO_8601,
            moment.RFC_2822
        ];

        const date = moment(dateString, validFormats, true);
        if (date.isValid()) {
            // note: make sure it was not false positive check on an integer value
            return validDateSeparators.some(separator => StringHelper.count(dateString, separator) === 2);
        }
        return false;
    }

    static isDateTimeString(dateString: any): dateString is string {
        if (!this.isDateString(dateString)) {
            return false;
        }
        const validDTimeSeparators = ['T', ' '];
        
        return validDTimeSeparators.some(separator => dateString.includes(separator) && dateString.split(separator).length === 2);
    }
}

import { isJSON } from '@/utilities/is-json.function';
enum LocalStorageKeys {
    Token = "ApportUserSession-"
}

class StorageService {
    Session = new SessionStorage();
    Local = new LocalStorage();
    Token = new LocalStorageToken();
}

class SessionStorage {
    private storage = window.sessionStorage;

    get<T>(key: string): T {
        const item = this.storage.getItem(key);
        return isJSON(item) ? JSON.parse(item!) : item;
    }
    set(key: string, value: any) {
        this.storage.setItem(key, JSON.stringify(value));
    }
    remove(key: string) {
        this.storage.removeItem(key);
    }
}

class LocalStorage {
    private storage = window.localStorage;

    get<T>(key: string): T {
        const item = this.storage.getItem(key);
        return isJSON(item) ? JSON.parse(item!) : item;
    }
    set(key: string, value: any) {
        this.storage.setItem(key, JSON.stringify(value));
    }
    remove(key: string) {
        this.storage.removeItem(key);
    }
}

class LocalStorageToken {
    private storage = window.localStorage;

    set(userName: string, token: string) {
        this.storage.setItem(LocalStorageKeys.Token.concat(userName), token);
    }

    get(userName: string): string {

        const token =  localStorage.getItem(LocalStorageKeys.Token.concat(userName))
        if (!token) {
            return "";
        }
    
        return "Bearer " + token;
    }
    
    remove(userName: string) {
        localStorage.removeItem(LocalStorageKeys.Token.concat(userName))
    }
}
export const $storage = new StorageService();
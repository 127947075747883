import { settingsModule } from '@/store/modules/settings.module';

export interface ICustomLoaderService {
    actionStart: () => void;
    
    actionEnd: () => void;
    
    actionLoader: () => boolean;
    
    modalStart: () => void;
    
    modalEnd: () => void;
    
    modalLoader: () => boolean;
    
    routeStart: () => void;
    
    routeEnd: () => void;
    
    routeLoader: () => boolean;

    detailStart: () => void;
    
    detailEnd: () => void;

    detailLoader: () => boolean;
    
    loaders: () => { action: number, route: number };
}

class CustomLoaderService implements ICustomLoaderService {
    actionStart() {
        settingsModule.setLoaderAction(1);
    }
    
    actionEnd() {
        settingsModule.setLoaderAction(-1);
    }
    
    actionLoader(): boolean {
        return settingsModule.getLoaderAction;
    }
    
    modalStart() {
        settingsModule.setLoaderModal(1);
    }
    
    modalEnd() {
        settingsModule.setLoaderModal(-1);
    }
    
    modalLoader(): boolean {
        return settingsModule.getLoaderModal;
    }
    
    routeStart() {
        settingsModule.setLoaderRoute(1);
    }
    
    routeEnd() {
        settingsModule.setLoaderRoute(-1);
    }
    
    routeLoader(): boolean {
        return settingsModule.getLoaderRoute;
    }

    detailStart() {
        settingsModule.setLoaderDetail(1);
    }
    
    detailEnd() {
        settingsModule.setLoaderDetail(-1);
    }
    
    detailLoader(): boolean {
        return settingsModule.getLoaderDetail;
    }
    
    loaders() {
        return settingsModule.loader;
    }

    closeAll() {
        const activeLoaders = this.loaders();
        const { route, action, modal, detail } = activeLoaders;

        if (route) {
            this.routeEnd();
        }
        if (action) {
            this.actionEnd();
        }
        if (modal) {
            this.modalEnd();
        }
        if (detail) {
            this.detailEnd();
        }
    }
}

export const loaderService = new CustomLoaderService();

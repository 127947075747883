import store from '../index';
import { ILanguage } from '@/interfaces/common.interface';
import { IAuthResponse } from '@/interfaces/web-api/login.interface';
import { HasFeatureType, IRouteConfig, IRouteConfigExtended } from '@/interfaces/settings.interface';
import { IUserRole } from '@/interfaces/web-api/user.interface';
import { cookieService } from '@/services/cookie.service';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ISettingsState } from '../states/settings-state.interface';
import { $environment } from '@/services/environment.service';
import jwtDecode from "jwt-decode";
import { $storage } from '@/services/storage.service';
import { FeatureFlag, IApportJwt } from '@/interfaces/web-api/feature-fag.interfaces';

@Module({ dynamic: true, store, name: 'settings' })
class SettingsModule extends VuexModule implements ISettingsState {
    availableLanguage: ILanguage[] = [];
    availableUserRoles: IUserRole[] = [];
    businessUnitName: string = '';
    displayName: string = '';
    version: string = $environment.version;
    token: string = '';
    rowsLimit: number = 10000;
    loader = {
        route: 0,
        action: 0,
        modal: 0,
        detail: 0,
    };
    routes: IRouteConfig[] = [];
    enabledFeatures: string[] = [];
    menuRoutes : IRouteConfigExtended[] = [];
    
    get settingsReady() {
        return this.routes.length > 0;
    }

    get getToken(): string | null {
        let token = this.token;
        const localStorageToken = $storage.Token.get(store.getters.userName);     

        if (!token && localStorageToken) {
            token = localStorageToken;
            store.commit('setToken', token);
            settingsModule.setEnabledFeaturesForToken(token);
        }

        if (token && !localStorageToken) {
            return null;
        }

        return token;
    }

    get getDisplayName(): string | null {
        if (this.displayName) {
            return this.displayName;
        }

        return this.getBusinessUnitName;
    }
    
    get getRowsLimit(): number {
        return this.rowsLimit;
    }

    get getBusinessUnitName(): string | null {
        if (this.businessUnitName) {
            return this.businessUnitName;
        }

        return cookieService.getCookie<string>(cookieService.keys.BUM);
    }

    get getDefaultLanguage(): string | null {
        return cookieService.getCookie<string>(cookieService.keys.LANG);
    }

    get getLoaderAction(): boolean {
        return this.loader.action > 0;
    }

    get getLoaderRoute(): boolean {
        return this.loader.route > 0;
    }
    
    get getLoaderModal(): boolean {
        return this.loader.modal > 0;
    }

    get getLoaderDetail(): boolean {
        return this.loader.detail > 0;
    }

    get getEnabledFeatures(): string[] {
        return this.enabledFeatures;
    }

    get hasFeature(): HasFeatureType {
        return (featureFlag: FeatureFlag): boolean => {
            const isEnabled = this.enabledFeatures.indexOf(featureFlag) > -1;
            return isEnabled;
        }
    }

    get getMenuRoutes(): IRouteConfigExtended[] {
        return this.menuRoutes;
    }

    @Mutation
    setAvailableLanguages(languages: ILanguage[]): void {
        this.availableLanguage = languages;
    }

    @Mutation
    setAvailableUserRoles(roles: IUserRole[]): void {
        this.availableUserRoles = roles;
    }

    @Mutation
    setBusinessUnitName(businessUnit: string): void {
        this.businessUnitName = businessUnit;
    }

    @Mutation
    setBusinessUnitNameCookie(businessUnit: string): void {
        cookieService.addCookie(cookieService.keys.BUM, businessUnit);
    }
    
    @Mutation
    setDisplayName(displayName: string): void {
        this.displayName = displayName;
    }

    @Mutation
    setRowsLimit(rowsLimit: number): void {
        this.rowsLimit = rowsLimit;
    }

    @Mutation
    setDefaultLanguageCookie(defaultLanguage: string): void {
        cookieService.addCookie(cookieService.keys.LANG, defaultLanguage);
    }

    @Mutation
    setToken(authToken: string): void {
        this.token = authToken;
    }

    @Mutation
    deleteTokenLocalStorage(): void {
        $storage.Token.remove(store.getters.userName);
    }

    @Mutation
    setLoaderAction(counter: number): void {
        this.loader.action += counter;

        this.loader.action = this.loader.action < 0 ? 0 : this.loader.action;
    }

    @Mutation
    setLoaderRoute(counter: number): void {
        this.loader.route += counter;

        this.loader.route = this.loader.route < 0 ? 0 : this.loader.route;
    }

    @Mutation
    setLoaderModal(counter: number): void {
        this.loader.modal += counter;

        this.loader.modal = this.loader.modal < 0 ? 0 : this.loader.modal;
    }

    @Mutation
    setLoaderDetail(counter: number): void {
        this.loader.detail += counter;

        this.loader.detail = this.loader.detail < 0 ? 0 : this.loader.detail;
    }

    @Mutation
    setRoutes(routes: IRouteConfig[]): void {
        this.routes = routes;
    }

    @Mutation
    setEnabledFeatures(enabledFeatures: string[]): void {
        this.enabledFeatures = enabledFeatures;
    }

    @Mutation
    setEnabledFeaturesForToken(accessToken: string): void {
        const hasTokenType = accessToken.indexOf(' ') > -1;
        const token = hasTokenType
            ? accessToken.split(' ')[1]
            : accessToken;
            
        const tokenData = jwtDecode<IApportJwt>(token);
        
        function isClaims(x: any): x is { enabledFeature: any} {
            return !!x && 'enabledFeature' in x;
        }

        if (isClaims(tokenData)) {
            const enabledFeatures = Array.isArray(tokenData.enabledFeature) ? tokenData.enabledFeature : [tokenData.enabledFeature];
            settingsModule.setEnabledFeatures(enabledFeatures);
        }
    }

    @Mutation
    setMenuRoutes(routes: IRouteConfigExtended[]): void {
        this.menuRoutes = routes;
    }

    @Action
    async setUserSettings(response: IAuthResponse) {
        const { accessToken, businessUnitName, defaultLanguage, expiresIn, tokenType } = response;
        const authToken = `${tokenType} ${accessToken}`;

        settingsModule.setToken(authToken);
        settingsModule.setBusinessUnitNameCookie(businessUnitName);
        settingsModule.setBusinessUnitName(businessUnitName);

        if (!settingsModule.getDefaultLanguage) {
            // note: store defaultLanguage only if language was not selected before in cookies
            settingsModule.setDefaultLanguageCookie(defaultLanguage);
        }
        
        this.setEnabledFeaturesForToken(accessToken);

        return;
    }

    @Action
    async disposeToken() {
        this.deleteTokenLocalStorage();
        this.setToken('');
        
        return;
    }
}

export const settingsModule = getModule(SettingsModule);

import { ICustomModalConfig } from '@/interfaces/custom-modal.interface';
import { $modalService } from '@/services/custom-modal.service';
import { $webApi } from '@/services/web-api.service';
import Vue from 'vue';
import { deepClone } from '@/utilities/deep-clone.function';
import DateHelper from "@/utilities/date.helper";
import { WidgetTypes } from '@/utilities/widget.enum';

interface IState {
    data: Array<IWidgetData>;
}

export default class WIdgetService extends Vue {
    protected State: IState = {
        data: [
            {
                "group": this.$T('Widget.OrderGroup').value,
                "values": [
                    {
                        "component": 'SingleValueWidget',
                        "valueJson": null,
                        "value": this.$T('Widget.OutGoingPerdayvalue').value,
                        "field": "status",
                        "title": this.$T('Widget.OutGoingPerdayheadline').value,
                        "pageName": "outgoing-orders-es-page",
                        "type": 0,
                        "isEnabled": true,
                        "imgUrl": require('@/assets/icons/outgoing-orders.svg'),
                        "filters":[
                            {"selector":"state","operator":"IsEqualTo","valueStart":"Completed","value":"Completed","type":"enum"},
                            {"selector":"completedDate","operator":"IsGreaterThan","value":this.getLatestPrevDayTime(),"valueStart":this.getLatestPrevDayTime(),"valueEnd":"","values":[],"type":"datetime"}]
                    },
                    {
                        "component": 'SingleValueWidget',
                        "valueJson": null,
                        "value": this.$T('Widget.OutGoingPendingvalue').value,
                        "title": this.$T('Widget.OutGoingPendingheadline').value,
                        "field": "status",
                        "type": 1,
                        "isEnabled": true,
                        "pageName": "outgoing-orders-es-page",
                        "imgUrl": require('@/assets/icons/outgoing-orders.svg'),
                        "filters": [
                            {
                                "selector": "state",
                                "operator": "In",
                                "value": "",
                                "valueStart": "",
                                "valueEnd": "",
                                "values": ["Initial", "Started"],
                                "type": "enum"
                              },
                                {
                                "operator": "Between",
                                "selector": "executionDate",
                                "type": "datetime",
                                "value": "1990-06-13T12:18:00.000",
                                "valueEnd": this.getLatestTodayTime(),
                                "valueStart": "1990-06-13T12:18:00.000",
                                "values": []
                              }
                              
                        ]

                    },
                    {
                        "component": 'SingleValueWidget',
                        "valueJson": null,
                        "value": this.$T('Widget.TaskEnableToPreparevalue').value,
                        "field": "status",
                        "title": this.$T('Widget.TaskEnableToPrepareheadline').value,
                        "pageName": "tasks-es-page",
                        "type": 2,
                        "isEnabled": true,
                        "imgUrl": require('@/assets/icons/outgoing-orders.svg'),
                        "filters":[{"selector":"stockIsMissing","operator":"IsEqualTo","value":"True","valueStart":"True","valueEnd":"","values":[],"type":"boolean"}]
                    },
                    {
                        "component": 'SingleValueWidget',
                        "valueJson": null,
                        "value": this.$T('Widget.OutGoingReadyToPickvalue').value,
                        "field": "status",
                        "title": this.$T('Widget.OutGoingReadyToPickheadline').value,
                        "pageName": "operations-es-page",
                        "type": 3,
                        "isEnabled": true,
                        "imgUrl": require('@/assets/icons/outgoing-orders.svg'),
                        "filters":[
                            {
                              "selector": "processDefinitionCategory",
                              "operator": "IsEqualTo",
                              "valueStart": "outgoingorders",
                              "value": "outgoingorders",
                              "valueEnd": "",
                              "values": [],
                              "type": "string"
                            },
                            {
                              "selector": "operationsSummarizedState",
                              "operator": "In",
                              "value": "",
                              "valueStart": "",
                              "valueEnd": "",
                              "values": ["Prepared"],
                              "type": "list"
                            }
                          ]
                    },
                    {
                        "component": 'SingleValueWidget',
                        "valueJson": null,
                        "value": this.$T('Widget.IngoingOrderStartedValue').value,
                        "field": "status",
                        "title": this.$T('Widget.IngoingOrderStartedheadline').value,
                        "pageName": "ingoing-orders-es-page",
                        "type": 4,
                        "isEnabled": true,
                        "imgUrl": require('@/assets/icons/ingoing-orders.svg'),
                        "filters":[
                            {
                                "selector":"state",
                                "operator":"IsEqualTo",
                                "valueStart":"Started",
                                "value":"Started",
                                "type":"enum"
                            }
                        ]
                    },
                    {
                        "component": 'SingleValueWidget',
                        "valueJson": null,
                        "value": this.$T('Widget.OutGoingStartedvalue').value,
                        "title": this.$T('Widget.OutGoingStartedheadline').value,
                        "field": "status",
                        "type": 5,
                        "isEnabled": true,
                        "pageName": "outgoing-orders-es-page",
                        "imgUrl": require('@/assets/icons/outgoing-orders.svg'),
                        "filters": [
                            {
                                "selector": "state",
                                "operator": "In",
                                "value": "",
                                "valueStart": "",
                                "valueEnd": "",
                                "values": ["Started"],
                                "type": "enum"
                              }
                        ]

                    },
                ]
            }
        ]
    };

    syncData(widgets: Array<IWidgets>) {
        
        const dataCopy = deepClone<Array<IWidgetData>>(this.State.data);
        const data = dataCopy.map((group: IWidgetData) => {
            group.values = group.values.filter((item: IWidgetItem) => {
                const serverItem = widgets.find((serverItem: IWidgets) => serverItem.type === item.type);
                if (serverItem) {
                    item.isEnabled = serverItem.isEnabled;
                    item.valueJson = serverItem.valueJson;
                    item.type = Object.values(WidgetTypes).find(
                        (value: any) => WidgetTypes[value] === item.type
                    )!;
                    return true; // Keep the item
                }
                return false; // Remove the item
            });

            return group;
        });
        return data;
    }
    async getWidgetsData(source: string = 'mounted', IsSettingsPage?: boolean) {
        if (source == 'fromWatcher' && IsSettingsPage) {
            return;
        }
        const widgets = await $webApi.GET.AvailalebleWidgets.Get();
        return widgets;

    }

    public getLatestPrevDayTime():string {
        let currentDate = new Date(); // Get the current date and time
        currentDate.setDate(currentDate.getDate() - 1); // Subtract one day
        currentDate.setHours(23, 59, 59, 999);
        return DateHelper.format(currentDate, 'YYYY-MM-DDTHH:mm:ss.sss')
    }

    public getLatestTodayTime():string {
        let currentDate = new Date(); // Get the current date and time
        currentDate.setHours(23, 59, 59, 999);
        return DateHelper.format(currentDate, 'YYYY-MM-DDTHH:mm:ss.sss')
    }
    
    public async saveWidget(selected: Array<string | number>) {
        const command: Command = {
            "ClientId": "Cockpit",
            "Owner": "userSettings",
            "Key": "EnabledWidgets",
            "Value": (selected).join()
        };
        await $webApi.POST.SaveSelectedWidgets.Create(command);
        const modalConfig: ICustomModalConfig = {
            template: 'SuccessModal',
            dynamic: {}
        };

        $modalService.show(modalConfig);
    }

}
